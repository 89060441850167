<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">店铺信息</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <div class="job_describe_cengter_header">{{ info.Shop_Name }}</div>

          <div class="user_undergo_box">
            <div class="user_undergo user_undergo_a">
              店&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;招：{{
                info.Shop_Sign
              }}
            </div>
            <div class="user_undergo user_undergo_a">
              信用代码：{{ info.Uniscid }}
            </div>
            <div class="user_undergo user_undergo_a">
              经营地址：{{ info.Shop_Address }}
            </div>
            <div class="user_undergo user_undergo_a">
              店铺电话：{{ info.Shop_Tel }}
            </div>
            <div class="user_undergo user_undergo_a">
              法&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;人：{{
                info.Legal_Name
              }}
            </div>
            <div class="user_undergo user_undergo_a">
              手&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;机：{{ info.Mobile }}
            </div>
            <div class="user_undergo user_undergo_a">
              店铺类型：{{ info.ShopTypeText }}
            </div>
            <div class="user_undergo user_undergo_a">
              备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：{{ info.Remark }}
            </div>
            <div class="user_undergo user_undergo_a">
              经营范围：{{ info.Business }}
            </div>

            <div class="user_undergo user_undergo_a">
              注册号码：{{ info.Regist_Number }}
            </div>
            <div class="user_undergo user_undergo_a">
              所属中队：{{ info.TeamText }}
            </div>
            <div class="user_undergo user_undergo_a">
              所属网格：{{ info.CellText }}
            </div>
            <div class="user_undergo user_undergo_a">
              网格管理员：{{ info.PersonText }}
            </div>
            <div class="user_undergo user_undergo_a">
              坐&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;标：{{
                info.Coordinate
              }}
            </div>
            <div class="user_undergo user_undergo_a">
              重点关注：
            </div>
            <div class="user_undergo user_undergo_a">
              <van-radio-group
                v-model="focusObject"
                direction="horizontal"
                disabled
              >
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </div>
            <div class="user_undergo user_undergo_a">
              门前三包：
            </div>
            <div class="user_undergo user_undergo_a">
              <van-radio-group
                v-model="outDoor"
                direction="horizontal"
                disabled
              >
                <van-radio name="3">优秀</van-radio>
                <van-radio name="2">良好</van-radio>
                <van-radio name="1">一般</van-radio>
              </van-radio-group>
            </div>
            <div class="user_undergo user_undergo_a">
              垃圾分类：
            </div>
            <div class="user_undergo user_undergo_a">
              <van-checkbox-group
                v-model="rubbishPart"
                direction="horizontal"
                disabled
              >
                <van-checkbox name="垃圾实行袋装化" shape="square">
                  垃圾实行袋装化
                </van-checkbox>
                <van-checkbox name="分类垃圾桶" shape="square">
                  分类垃圾桶
                </van-checkbox>
                <van-checkbox name="垃圾分类投放" shape="square">
                  垃圾分类投放
                </van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="user_undergo user_undergo_a">
              义工情况：
            </div>
            <div class="user_undergo user_undergo_a">
              <van-checkbox-group
                v-model="cityVolunteer"
                direction="horizontal"
                disabled
              >
                <van-checkbox name="参与过城管义工活动" shape="square">
                  参与过城管义工活动
                </van-checkbox>
                <van-checkbox name="是城管义工" shape="square">
                  是城管义工
                </van-checkbox>
              </van-checkbox-group>
            </div>
            <div class="user_undergo user_undergo_a">
              加水点：
            </div>
            <div class="user_undergo user_undergo_a">
              <van-radio-group
                v-model="waterAdd"
                direction="horizontal"
                disabled
              >
                <van-radio name="1">是</van-radio>
                <van-radio name="0">否</van-radio>
              </van-radio-group>
            </div>
            <div>
              <div class="user_undergo user_undergo_a">
                近景照片：
              </div>
              <img
                v-for="(item, i) in info.CloseShotsList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="closeShotsPreview"
              />
            </div>
            <div>
              <div class="user_undergo user_undergo_a">
                远景照片：
              </div>
              <img
                v-for="(item, i) in info.DistantShotsList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="distantShotsPreview"
              />
            </div>
            <div>
              <div class="user_undergo user_undergo_a">
                门前三包照片：
              </div>
              <img
                v-for="(item, i) in info.OutDoorShotsList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="outDoorShotsPreview"
              />
            </div>
            <div>
              <div class="user_undergo user_undergo_a">
                证件照片：
              </div>
              <img
                v-for="(item, i) in info.CertificateShotsList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="certificateShotsPreview"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="corporate_information">
        <div class="job_describe_top">
          <div class="jjxx">业主信息</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter2">
          <div class="user_undergo user_undergo_a">
            业主姓名：{{ info.Owner_Name }}
          </div>
          <div class="user_undergo user_undergo_a">
            业主手机：{{ info.Owner_Phone }}
          </div>
          <div class="user_undergo user_undergo_a">
            业主地址：{{ info.Owner_Address }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Base64 from '@/util/Base64'
  import { systemUrl } from '@/config'
  import { Toast } from 'vant'
  import { ImagePreview } from 'vant'
  import { getShopInfo } from '@/api/workBench/shop/shop'
  import {
    getShopType,
    getTeam,
    getTeamCell,
    getCellPerson,
  } from '@/api/base/base'
  import { Emas } from '@/assets/js/Emas'
  import store from '@/store'

  export default {
    name: 'ShopInfo',
    components: {},
    data() {
      return {
        shopId: 0,
        systemUrl,
        shopType: '',
        team: '',
        teamCell: '',
        cellPerson: '',
        focusObject: null,
        outDoor: null,
        rubbishPart: [],
        cityVolunteer: [],
        waterAdd: null,
        info: {}, //详情数据
        closeShotsList: [],
        distantShotsList: [],
        outDoorShotsList: [],
        certificateShotsList: [],
      }
    },
    created() {
      this.shopId =
        this.$route.query.shopId === undefined
          ? 0
          : Base64.decode(this.$route.query.shopId)
      // this.shopId = 4203
      this.fetchData()
      const teamInfo = JSON.parse(store.getters['user/teamInfo'])
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'shopInfo',
        '城管-店铺详情',
        'zzdcg.yy.gov.cn/shopInfo'
      )
    },

    methods: {
      //获取店铺详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getShopInfo({
          Shop_ID: this.shopId,
          KeyWord: '',
          Type: 4,
        })
        data.CloseShotsList = this.makeFileUrl(data.CloseShotsList)
        data.DistantShotsList = this.makeFileUrl(data.DistantShotsList)
        data.OutDoorShotsList = this.makeFileUrl(data.OutDoorShotsList)
        data.CertificateShotsList = this.makeFileUrl(data.CertificateShotsList)
        this.info = data

        this.selectTransform(data)
        Toast.clear()
      },

      //路径拼接
      makeFileUrl(fileList) {
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            fileList[i].Url = systemUrl + fileList[i].Url
            switch (fileList[i].Type) {
              case 1:
                this.closeShotsList.push(fileList[i].Url)
                break
              case 2:
                this.distantShotsList.push(fileList[i].Url)
                break
              case 3:
                this.outDoorShotsList.push(fileList[i].Url)
                break
              case 7:
                this.certificateShotsList.push(fileList[i].Url)
                break
            }
          }
        }
        return fileList
      },

      //单选多选做绑定转换
      selectTransform(info) {
        this.focusObject = info.FocusObject == '是' ? '1' : '0'

        if (info.OutDoor == '优秀') this.outDoor = '3'
        else if (info.OutDoor == '良好') this.outDoor = '2'
        else this.outDoor = '1'

        this.waterAdd = info.WaterAdd == '是' ? '1' : '0'

        this.rubbishPart = info.RubbishPart.split(',')
        this.cityVolunteer = info.CityVolunteer.split(',')
      },

      //照片预览
      closeShotsPreview() {
        ImagePreview({
          images: this.closeShotsList,
        })
      },
      distantShotsPreview() {
        ImagePreview({
          images: this.distantShotsList,
        })
      },
      outDoorShotsPreview() {
        ImagePreview({
          images: this.outDoorShotsList,
        })
      },
      certificateShotsPreview() {
        ImagePreview({
          images: this.certificateShotsList,
        })
      },

      reply() {
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    /*display: inline-block;*/
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
</style>
